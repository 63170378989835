import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, getSrc } from "gatsby-plugin-image";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import styled from 'styled-components';

import LayoutNew from "../../components/layout-new";
import Seo from "../../components/seo";
import HeroSectionPodcasts from "../../components/sections/hero-section-podcasts";
import HeroSectionPodcastsMobileNew from "../../components/sections/hero-section-podcasts-mobile-new";
import SectionPodcastsGetStarted from '../../components/sections/section-podcasts-get-started';
import SectionPodcastsGetStartedMobile from '../../components/sections/section-podcasts-get-started-mobile';
import PortalPodcastUpdate from "../../components/common/portal-podcast-update";
import SectionPodcastsHostedByUpdate from '../../components/sections/section-podcasts-hosted-by-update';
import SectionPodcastsRequestNewUpdate from '../../components/sections/section-podcasts-request-new-update';

const StyledDiv = styled.div`
  .section-tab {
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 2px;
    padding: 5px 20px 8px;
    margin-right: 2px;
    margin-bottom: 2px;
    white-space: nowrap;
    flex-shrink: 0; /* Prevent tabs from shrinking */
    min-width: max-content; /* Ensure each tab has enough space */
  }

  @media (max-width: 767px) {
    .tabs {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      scrollbar-width: thin; /* For Firefox */
      -webkit-scrollbar-width: thin; /* For WebKit browsers */

      &::-webkit-scrollbar {
        height: 7px; /* Height of the scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc; /* Color of the scrollbar thumb */
        border-radius: 10px;
      }
    }
  }
`;

const PagePodcastsUpdate = () => {
  const data = useStaticQuery(graphql`
    query PagePodcastsUpdateQueryNewUpdate {
      prismicPodcastsPage {
        _previewable
        data {
          podcasts_page_title
          podcasts_page_short_description
          page_image_for_linkedin_share {
            alt
            gatsbyImageData(
              width: 1200
            )
          }
          top_title4
          main_title4
          podcasts_section {
            title
            about
            category_medical_dental
            category_manufacturing
            category_entrepreneurship
            category_finance_taxes
            category_professional_services
            category_construction
            category_real_estate
            category_agriculture
            category_insurance
            audio_url
            share_link {
              link_type
              uid
              url
            }
            podcast_image {
              alt
              gatsbyImageData(
                width: 300
                height: 300
              )
            }
            status
          }
          top_title
          main_title
          sub_title
          icons_title
          first_icon {
            gatsbyImageData(
              width: 800
            )
          }
          first_icon_link
          second_icon {
            gatsbyImageData(
              width: 800
            )
          }
          second_icon_link
          third_icon {
            gatsbyImageData(
              width: 800
            )
          }
          third_icon_link
          fourth_icon {
            gatsbyImageData(
              width: 800
            )
          }
          fourth_icon_link
          fifth_icon {
            gatsbyImageData(
              width: 800
            )
          }
          fifth_icon_link
          new_hero_background {
            gatsbyImageData(
              width: 1920
            )  
          }
        }
      }      
      imgCard: file(relativePath: { eq: "podcasts/sections/unmitigated-podcast-card.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 383
          )
        }
      }
    }
  `);

  const doc = data.prismicPodcastsPage;
  const imgHero = getImage(doc.data.new_hero_background);
  const imgIcon1 = getImage(doc.data.first_icon);
  const imgIcon2 = getImage(doc.data.second_icon);
  const imgIcon3 = getImage(doc.data.third_icon);
  const imgIcon4 = getImage(doc.data.fourth_icon);
  const imgIcon5 = getImage(doc.data.fifth_icon);
  const imgSrc = getSrc(doc.data.new_hero_background);
  const imgSrcLinkedIn = getSrc(doc.data.page_image_for_linkedin_share);

  const filterPodcasts = (category) => {
    const trueStatus = doc.data.podcasts_section.filter(podcast => podcast.status === true && (category ? podcast[category] : true));
    const falseStatus = doc.data.podcasts_section.filter(podcast => podcast.status === false && (category ? podcast[category] : true));
    trueStatus.sort().reverse();
    return [...trueStatus, ...falseStatus];
  };

  const allPodcasts = filterPodcasts(null);
  const medicalPodcasts = filterPodcasts('category_medical_dental');
  const manufacturingPodcasts = filterPodcasts('category_manufacturing');
  const entrepreneurshipPodcasts = filterPodcasts('category_entrepreneurship');
  const financePodcasts = filterPodcasts('category_finance_taxes');
  const professionalPodcasts = filterPodcasts('category_professional_services');
  const constructionPodcasts = filterPodcasts('category_construction');
  const realEstatePodcasts = filterPodcasts('category_real_estate');
  const agriculturePodcasts = filterPodcasts('category_agriculture');
  const insurancePodcasts = filterPodcasts('category_insurance');

  const [selectedCategory, setSelectedCategory] = useState('All');
  const [displayedPodcasts, setDisplayedPodcasts] = useState(4);

  const loadMore = () => {
    setDisplayedPodcasts(prev => prev + 4);
  };

  const options = [
    'All', 
    'Medical & Dental', 
    'Manufacturing', 
    'Entrepreneurship', 
    'Finance & Taxes', 
    'Professional Services', 
    'Construction', 
    'Real Estate', 
    'Agriculture', 
    'Insurance'
  ];

  const getPodcastsByCategory = () => {
    switch (selectedCategory) {
      case 'Medical & Dental':
        return medicalPodcasts;
      case 'Manufacturing':
        return manufacturingPodcasts;
      case 'Entrepreneurship':
        return entrepreneurshipPodcasts;
      case 'Finance & Taxes':
        return financePodcasts;
      case 'Professional Services':
        return professionalPodcasts;
      case 'Construction':
        return constructionPodcasts;
      case 'Real Estate':
        return realEstatePodcasts;
      case 'Agriculture':
        return agriculturePodcasts;
      case 'Insurance':
        return insurancePodcasts;
      default:
        return allPodcasts;
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const categoryMap = {
        'all': 'All',
        'medical-dental': 'Medical & Dental',
        'manufacturing': 'Manufacturing',
        'entrepreneurship': 'Entrepreneurship',
        'finance-taxes': 'Finance & Taxes',
        'professional-services': 'Professional Services',
        'construction': 'Construction',
        'real-estate': 'Real Estate',
        'agriculture': 'Agriculture',
        'insurance': 'Insurance'
      };
      if (categoryMap[hash]) {
        setSelectedCategory(categoryMap[hash]);
        setDisplayedPodcasts(4);
  
        // Scroll the selected button into view
        setTimeout(() => {
          const element = document.getElementById(hash);
          const featuredPodcasts = document.getElementById('featured-podcasts');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          }
          if (featuredPodcasts) {
            window.scrollTo({ top: featuredPodcasts.offsetTop, behavior: 'smooth' });
          }
        }, 0);
  
        // Hide the above sections
        document.querySelectorAll('.above-featured').forEach(el => el.style.display = 'none');
      }
    };
  
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
  
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [selectedCategory]);

  const tabsRef = useRef(null);

  const handleTabClick = (option) => {
    setSelectedCategory(option);
    setDisplayedPodcasts(4);
    const tabElement = document.getElementById(option.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-'));
    const tabsContainer = tabsRef.current;
    if (tabsContainer && tabElement) {
      const containerRect = tabsContainer.getBoundingClientRect();
      const tabRect = tabElement.getBoundingClientRect();
      const offset = tabRect.left - containerRect.left - (containerRect.width / 2) + (tabRect.width / 2);
      tabsContainer.scrollLeft += offset;
    }
    window.location.hash = option.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-');
  };

  const [showLeftArrow, setShowLeftArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (tabsRef.current) {
        setShowLeftArrow(tabsRef.current.scrollLeft > 0);
      }
    };

    const tabsElement = tabsRef.current;
    if (tabsElement) {
      tabsElement.addEventListener('scroll', handleScroll);
      handleScroll(); // Check initial scroll position
    }

    return () => {
      if (tabsElement) {
        tabsElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <LayoutNew>
      <Seo
        title={doc.data.podcasts_page_title}
        description={doc.data.podcasts_page_short_description}
        image={imgSrcLinkedIn ? imgSrcLinkedIn : imgSrc}
      />

      <HeroSectionPodcasts
        introTitle={doc.data.top_title}
        title={doc.data.main_title}
        subTitle={doc.data.sub_title}
        iconsSectionTitle={doc.data.icons_title}
        imgSrc={imgHero}
        imgIcon1Src={imgIcon1}
        imgIcon1SrcUrl={doc.data.first_icon_link}
        imgIcon2Src={imgIcon2}
        imgIcon2SrcUrl={doc.data.second_icon_link}
        imgIcon3Src={imgIcon3}
        imgIcon3SrcUrl={doc.data.third_icon_link}
        imgIcon4Src={imgIcon4}
        imgIcon4SrcUrl={doc.data.fourth_icon_link}
        imgIcon5Src={imgIcon5}
        imgIcon5SrcUrl={doc.data.fifth_icon_link}
        className="above-featured"
      />

      <HeroSectionPodcastsMobileNew
        introTitle={doc.data.top_title}
        title={doc.data.main_title}
        subTitle={doc.data.sub_title}
        iconsSectionTitle={doc.data.icons_title}
        imgSrc={imgHero}
        imgIcon1Src={imgIcon1}
        imgIcon1SrcUrl={doc.data.first_icon_link}
        imgIcon2Src={imgIcon2}
        imgIcon2SrcUrl={doc.data.second_icon_link}
        imgIcon3Src={imgIcon3}
        imgIcon3SrcUrl={doc.data.third_icon_link}
        imgIcon4Src={imgIcon4}
        imgIcon4SrcUrl={doc.data.fourth_icon_link}
        imgIcon5Src={imgIcon5}
        imgIcon5SrcUrl={doc.data.fifth_icon_link}
        className="above-featured"
      />

      <SectionPodcastsGetStarted />
      <SectionPodcastsGetStartedMobile />

      <div id="featured-podcasts" className="bg-site-gray-new pt-16 md:pt-20 pb-8 md:pb-16 z-10">
        <div className="w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
          <p className='text-gray-100 text-lg md:text-3xl text-center uppercase mb-2 tracking-widest'>{doc.data.top_title4}</p>
          <h2 className="text-white text-2xl md:text-5xl text-center font-extrabold uppercase tracking-wider">{doc.data.main_title4}</h2>
        </div>

        <StyledDiv className="w-11/12 max-w-screen-xl mt-8 mx-auto">
          <div ref={tabsRef} className="w-full max-w-screen-lg tabs flex flex-wrap md:flex-none items-start md:items-center justify-start md:justify-center mb-4 mx-auto z-10">            
            {options.map((option, index) => (
              <button
                key={option}
                id={option.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-')}
                onClick={() => handleTabClick(option)}
                className={`section-tab hover:bg-site-red ${selectedCategory === option && 'bg-site-red'}`}
              >
                {option}
              </button>
            ))}
          </div>

          <div className="md:hidden flex items-center justify-between z-20 -mt-4">
            {showLeftArrow ? <span className='block text-white text-2xl'>⟵</span> : <span className='block text-white text-2xl'></span>}
            <span className='block text-white text-2xl'>⟶</span>
          </div>

          <div>
            <h2 className="text-gray-200 text-xl md:text-3xl text-center font-extrabold uppercase tracking-wider mt-8">{selectedCategory} Podcasts</h2>
          </div>

          <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">
            {getPodcastsByCategory().slice(0, displayedPodcasts).map((podcast, i) => {
              const podcastImg = getImage(podcast.podcast_image);

              return (
                <PortalPodcastUpdate
                  key={podcast.title}  // Use a unique key for each podcast item
                  podcastImg={podcastImg}
                  podcastTitle={podcast.title}
                  podcastText={podcast.about}
                  audioUrl={podcast.audio_url}
                  buttonLinkType={podcast.share_link.link_type}
                  buttonLinkUid={podcast.share_link.uid}
                  buttonLinkUrl={podcast.share_link.url}
                />
              );
            })}
          </div>
          
          {displayedPodcasts < getPodcastsByCategory().length && (
            <div className="flex justify-center mt-4">
              <button
                className="w-full md:w-96 bg-site-red hover:bg-site-red-hover text-base text-white md:text-lg uppercase px-8 py-3 mt-4 lg:mt-0"
                onClick={loadMore}
              >
                Load More
              </button>
            </div>
          )}
        </StyledDiv>
      </div>

      <SectionPodcastsHostedByUpdate />
      <SectionPodcastsRequestNewUpdate />
    </LayoutNew>
  );
};

export default withPrismicPreview(PagePodcastsUpdate);