import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: auto;
  height: 550px;

  @media (max-width: 767px) {
    height: auto;
  }

  @media (max-width: 1023px) {
    height: auto;
  }
`

const StyledDiv2 = styled.div`
  .rhap_container {
    box-shadow: unset;
    background-color: #333333;
    padding-bottom: 0px;
  }
  .rhap_main-controls-button {
    color: #ffffff;
  }
  .rhap_main-controls-button:hover {
    color: #bb0000;
  }
  .rhap_button-clear {
    background-color: transparent;
  }

  @media (max-width: 1024px) {
    .rhap_container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`

const StyledDiv3 = styled.div`
  width: auto;
  height: auto;
`

const PortalPodcastUpdate = ({ podcastImg, podcastTitle, audioUrl, buttonLinkType, buttonLinkUrl, buttonLinkUid }) => {
  const data = useStaticQuery(graphql`
    query PortalPodcastUpdateQuery {
      cardImgPlaceholder: file(relativePath: { eq: "cards/podcasts-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
          )
        }
      }
    }
  `)

  const cardImgPlaceholder = getImage(data.cardImgPlaceholder)

  return (
    <div className='relative w-full bg-site-gray-dark shadow-md mt-4 mb-4'>
      <div className='p-4'>
        <div className='block'>
          <StyledDiv className='flex flex-row lg:flex-col items-start lg:items-center space-x-3 lg:space-x-0'>
            <StyledDiv3 className='w-4/12 lg:w-full'>
              <GatsbyImage image={podcastImg ? podcastImg : cardImgPlaceholder} objectFit="fill" alt="Podcast Image" className='border mb-2' />

              <div className='block md:hidden'>
                {buttonLinkType === "Document" || buttonLinkType === "Any"
                  ?
                    <Link
                      to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}
                    >
                      <button className="w-full flex items-center justify-center px-4 py-1 md:px-5 md:py-3 border border-transparent font-bold text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                        Share
                      </button>
                    </Link>
                  :
                  buttonLinkType === "Web" || buttonLinkType === "Media"
                  ?
                    <a
                      href={buttonLinkUrl ? buttonLinkUrl : 'https://www.831b.com'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full flex items-center justify-center px-4 py-1 md:px-5 md:py-3 border border-transparent font-bold text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                        Share
                      </button>
                    </a>
                  :
                  null
                }
              </div>
            </StyledDiv3>

            <div className='w-8/12 lg:w-full flex flex-col items-start'>
              <h2 className='text-md md:text-xl text-left lg:text-center text-white font-bold mb-2'>{podcastTitle ? podcastTitle : 'Podcast Title'}</h2>

              {audioUrl
                ?
                <StyledDiv2 className='block md:hidden'>
                  <AudioPlayer src={audioUrl} />
                </StyledDiv2>
                :
                null
              }

              <div className='hidden md:flex lg:hidden items-center space-x-8 mt-4'>
                {audioUrl
                  ?
                  <StyledDiv2 className='block lg:hidden'>
                    <AudioPlayer src={audioUrl} />
                  </StyledDiv2>
                  :
                  null
                }

                {buttonLinkType === "Document" || buttonLinkType === "Any"
                  ?
                    <Link
                      to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}
                    >
                      <button className="w-full flex items-center justify-center px-4 py-1 md:px-5 border border-transparent font-bold text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                        Share
                      </button>
                    </Link>
                  :
                  buttonLinkType === "Web" || buttonLinkType === "Media"
                  ?
                    <a
                      href={buttonLinkUrl ? buttonLinkUrl : 'https://www.831b.com'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full flex items-center justify-center px-4 py-1 md:px-5 border border-transparent font-bold text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                        Share
                      </button>
                    </a>
                  :
                  null
                }
              </div> 
            </div>
          </StyledDiv>

          <div className='w-11/12 hidden lg:block absolute bottom-0 left-0 right-0 mx-auto mb-4'>
            {buttonLinkType === "Document" || buttonLinkType === "Any"
              ?
                <Link
                  to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}
                >
                  <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 border border-transparent font-bold text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                    Share
                  </button>
                </Link>
              :
              buttonLinkType === "Web" || buttonLinkType === "Media"
              ?
                <a
                  href={buttonLinkUrl ? buttonLinkUrl : 'https://www.831b.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 border border-transparent font-bold text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                    Share
                  </button>
                </a>
              :
              null
            }

            {audioUrl
              ?
              <StyledDiv2>
                <AudioPlayer src={audioUrl} />
              </StyledDiv2>
              :
              null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

PortalPodcastUpdate.propTypes = {
  podcastImg: PropTypes.string,
  podcastTitle: PropTypes.string,
  podcastText: PropTypes.string,
  audioUrl: PropTypes.string,
  buttonLinkType: PropTypes.string,
  buttonLinkUrl: PropTypes.string,
  buttonLinkUid: PropTypes.string,
}

export default PortalPodcastUpdate