import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const SectionPodcastsGetStartedMobile = () => {
  const data = useStaticQuery(graphql`
    query SectionPodcastsGetStartedMobileQuery {
      prismicPodcastsPage {
        _previewable
        data {
          top_title1
          main_title1
          section_content
          button_name
          button_link {
            link_type
            uid
            url
          }
          first_image {
            gatsbyImageData(
              width: 800
            )
          }
          first_image_link {
            link_type
            uid
            url
          }
          second_image {
            gatsbyImageData(
              width: 800
            )
          }
          second_image_link {
            link_type
            uid
            url
          }
        }
      }
    }
  `)

  const doc = data.prismicPodcastsPage
  const imgFirst = getImage(doc.data.first_image)
  const imgSecond = getImage(doc.data.second_image)
  const buttonLinkType = doc.data.button_link.link_type
  const buttonLinkUid = doc.data.button_link.uid
  const buttonLinkUrl = doc.data.button_link.url
  const firstImageLinkType = doc.data.first_image_link.link_type
  const firstImageLinkUid = doc.data.first_image_link.uid
  const firstImageLinkUrl = doc.data.first_image_link.url
  const secondImageLinkType = doc.data.second_image_link.link_type
  const secondImageLinkUid = doc.data.second_image_link.uid
  const secondImageLinkUrl = doc.data.second_image_link.url
  

  return (    
    <div className="bg-white block lg:hidden">
      <div className="relative w-full pt-8 pb-10 md:pt-16 md:pb-14">
        <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto space-x-0 p-2'>
          <div className="w-full xl:w-1/2">
            <div className="z-20 flex flex-col items-center justify-center">
              <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-2 tracking-widest'>{doc.data.top_title1}</p>
              <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold uppercase tracking-wider mb-2">{doc.data.main_title1}</h2>
              <p className='text-site-gray-dark text-1xl md:text-2xl text-center lg:text-left mb-8'>{doc.data.section_content}</p>
            </div>
          </div>

          <div className="w-full flex items-center justify-center space-x-6">
            {firstImageLinkType === "Document" || firstImageLinkType === "Any"
              ?
                <Link to={firstImageLinkUid ? `/${firstImageLinkUid}` : "/"}>
                  <GatsbyImage
                    alt="unmitigated podcast"
                    image={imgFirst}
                    formats={["auto", "webp", "avif"]}
                    className="z-20"
                  />
                </Link>
              :
              firstImageLinkType === "Web" || firstImageLinkType === "Media"
              ?
                <a
                  href={firstImageLinkUrl ? firstImageLinkUrl : ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GatsbyImage
                    alt="unmitigated podcast"
                    image={imgFirst}
                    formats={["auto", "webp", "avif"]}
                    className="z-20"
                  />
                </a>
              :
              null
            }

            {secondImageLinkType === "Document" || secondImageLinkType === "Any"
              ?
                <Link to={secondImageLinkUid ? `/${secondImageLinkUid}` : "/"}>
                  <GatsbyImage
                    alt="unmitigated podcast"
                    image={imgSecond}
                    formats={["auto", "webp", "avif"]}
                    className="z-20"
                  />
                </Link>
              :
              secondImageLinkType === "Web" || secondImageLinkType === "Media"
              ?
                <a
                  href={secondImageLinkUrl ? secondImageLinkUrl : ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GatsbyImage
                    alt="unmitigated podcast"
                    image={imgSecond}
                    formats={["auto", "webp", "avif"]}
                    className="z-20"
                  />
                </a>
              :
              null
            }
          </div>

          <div className='w-full md:w-9/12 mt-8'>
            {buttonLinkType === "Document" || buttonLinkType === "Any"
              ?
                <Link to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}>
                  <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider py-3">
                    {doc.data.button_name}
                  </button>
                </Link>
              :
              buttonLinkType === "Web" || buttonLinkType === "Media"
              ?
                <a
                  href={buttonLinkUrl ? buttonLinkUrl : ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider py-3">
                    {doc.data.button_name}
                  </button>
                </a>
              :
              null
            }
          </div>
        </div>

        <div className="absolute top-0 lg:bottom-0 right-0 z-0">
          <StaticImage
            src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
            width={550}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
      </div> 
    </div>    
  )
}

export default SectionPodcastsGetStartedMobile