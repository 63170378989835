import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const SectionPodcastsGetStarted = () => {
  const data = useStaticQuery(graphql`
    query SectionPodcastsGetStartedQuery {
      prismicPodcastsPage {
        _previewable
        data {
          top_title1
          main_title1
          section_content
          button_name
          button_link {
            link_type
            uid
            url
          }
          first_image {
            gatsbyImageData(
              width: 800
            )
          }
          first_image_link {
            link_type
            uid
            url
          }
        }
      }
    }
  `)

  const doc = data.prismicPodcastsPage
  const imgFirst = getImage(doc.data.first_image)
  const buttonLinkType = doc.data.button_link.link_type
  const buttonLinkUid = doc.data.button_link.uid
  const buttonLinkUrl = doc.data.button_link.url
  const firstImageLinkType = doc.data.first_image_link.link_type
  const firstImageLinkUid = doc.data.first_image_link.uid
  const firstImageLinkUrl = doc.data.first_image_link.url

  return (    
    <div className="bg-white hidden lg:block">
      <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
        <div className='w-11/12 max-w-screen-xl flex items-center justify-center mx-auto space-x-16 p-2'>
          <div className="w-2/3">
            <div className="z-20 flex flex-col items-start justify-start">
              <p className='text-site-gray-dark text-3xl text-left uppercase mb-4 tracking-widest'>{doc.data.top_title1}</p>
              <h2 className="text-site-red text-5xl text-left font-extrabold uppercase tracking-wider mb-8">{doc.data.main_title1}</h2>
              <p className='text-site-gray-dark text-2xl text-left mb-8'>{doc.data.section_content}</p>

              <div className='lg:w-9/12 2xl:w-8/12'>
                {buttonLinkType === "Document" || buttonLinkType === "Any"
                  ?
                    <Link to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}>
                      <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.button_name}
                      </button>
                    </Link>
                  :
                  buttonLinkType === "Web" || buttonLinkType === "Media"
                  ?
                    <a
                      href={buttonLinkUrl ? buttonLinkUrl : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.button_name}
                      </button>
                    </a>
                  :
                  null
                }
              </div>
            </div>
          </div>
          <div className="w-1/3 flex flex-col items-center justify-center">
            {firstImageLinkType === "Document" || firstImageLinkType === "Any"
              ?
                <Link to={firstImageLinkUid ? `/${firstImageLinkUid}` : "/"}>
                  <GatsbyImage
                    alt="unmitigated podcast"
                    image={imgFirst}
                    formats={["auto", "webp", "avif"]}
                    className="z-20"
                  />
                </Link>
              :
              firstImageLinkType === "Web" || firstImageLinkType === "Media"
              ?
                <a
                  href={firstImageLinkUrl ? firstImageLinkUrl : ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GatsbyImage
                    alt="unmitigated podcast"
                    image={imgFirst}
                    formats={["auto", "webp", "avif"]}
                    className="z-20"
                  />
                </a>
              :
              null
            }
          </div>
        </div>

        <div className="absolute bottom-0 right-0 z-0">
          <StaticImage
            src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
            width={900}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
      </div> 
    </div>    
  )
}

export default SectionPodcastsGetStarted