import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSectionPodcasts = ({ imgSrc, title, introTitle, subTitle, iconsSectionTitle, imgIcon1Src, imgIcon1SrcUrl, imgIcon2Src, imgIcon2SrcUrl, imgIcon3Src, imgIcon3SrcUrl, imgIcon4Src, imgIcon4SrcUrl, imgIcon5Src, imgIcon5SrcUrl }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionPodcastsQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <div className="relative hidden md:grid pt-14 xl:pt-20 z-20" style={{ background: "#000000", minHeight: "400px", height: "100vh" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "fill",
          }}
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 max-w-screen-xl flex flex-col items-start justify-start px-2">
              <div className="w-full md:w-10/12 lg:w-7/12 flex flex-col justify-center">
                <p className="text-white text-md md:text-3xl xl:text-4xl uppercase tracking-widest mb-6">{introTitle}</p>
                <h2 className="text-site-red text-4xl md:text-7xl xl:text-8xl font-extrabold text-left uppercase tracking-widest mb-6">
                  {title}
                </h2>

                <p className="text-white text-1xl md:text-2xl xl:text-3xl tracking-wider mb-20">{subTitle}</p>

                <p className="text-white text-xl md:text-2xl font-bold tracking-widest mb-6">{iconsSectionTitle}</p>

                <div className="w-full md:w-6/12 grid grid-cols-5 gap-0 md:gap-24">
                  {imgIcon1Src
                    ?
                    <a href={imgIcon1SrcUrl ? imgIcon1SrcUrl : ""} target="_blank" rel="noopener noreferrer">
                      <div className="w-16 h-16">
                        <GatsbyImage
                          alt="podcast icon one"
                          image={imgIcon1Src}
                          formats={["auto", "webp", "avif"]}
                        />
                      </div>
                    </a>
                    :
                    null
                  }

                  {imgIcon2Src
                    ?
                    <a href={imgIcon2SrcUrl ? imgIcon2SrcUrl : ""} target="_blank" rel="noopener noreferrer">
                      <div className="w-16 h-16">
                        <GatsbyImage
                          alt="podcast icon two"
                          image={imgIcon2Src}
                          formats={["auto", "webp", "avif"]}
                        />
                      </div>
                    </a>
                    :
                    null
                  }

                  {imgIcon3Src
                    ?
                    <a href={imgIcon3SrcUrl ? imgIcon3SrcUrl : ""} target="_blank" rel="noopener noreferrer">
                      <div className="w-16 h-16">
                        <GatsbyImage
                          alt="podcast icon three"
                          image={imgIcon3Src}
                          formats={["auto", "webp", "avif"]}
                        />
                      </div>
                    </a>
                    :
                    null
                  }

                  {imgIcon4Src
                    ?
                    <a href={imgIcon4SrcUrl ? imgIcon4SrcUrl : ""} target="_blank" rel="noopener noreferrer">
                      <div className="w-16 h-16">
                        <GatsbyImage
                          alt="podcast icon four"
                          image={imgIcon4Src}
                          formats={["auto", "webp", "avif"]}
                        />
                      </div>
                    </a>
                    :
                    null
                  }

                  {imgIcon5Src
                    ?
                    <a href={imgIcon5SrcUrl ? imgIcon5SrcUrl : ""} target="_blank" rel="noopener noreferrer">
                      <div className="w-16 h-16">
                        <GatsbyImage
                          alt="podcast icon five"
                          image={imgIcon5Src}
                          formats={["auto", "webp", "avif"]}
                        />
                      </div>
                    </a>
                    :
                    null
                  }
                </div>
              </div>
            </div>
            :
            null
          }
        </div>
      </div>           
    </>
  )
}

export default HeroSectionPodcasts
